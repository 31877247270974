.site-footer {
	padding: 2em $columns-margin 2em;
	background-color: $pale-foam;
	
	@include query(desktop){
		padding: 2em element-width($number-columns: 1, $parent-total-columns: $grid-total-columns, $parent-outer-margin: 2, $outer-margin: 2) 1em;
		display: flex;
		justify-content: space-between;
	}
	
	.site-info {
	    text-align: center;
	    align-items: baseline; 
	    
	    @include query(desktop) {
		    text-align: right;
	    }
	    
	    p {
	      @include font-size(.9);
	      margin-bottom: 0;
	      margin-top: 0;
		}
		
		.copyright, a {
			color: black;
		}
	}
	
	.contact_info {
		text-align: center;
		margin-bottom: 1.5em;
		
		@include query(desktop) {
			margin: 0;
			text-align: right;
			margin-bottom: 1.5em;
		}
		
		.adr {
			p {
				margin: 0;
			}
		}
	}
	
	.contact_info a, .site-info a {
		position: relative;
		display: inline-block;
	}
	
	.contact_info a:before, .site-info a:before {
	  content: "";
	  position: absolute;
	  width: 100%;
	  height: 1px;
	  bottom: -3px;
	  left: 0;
	  background-color: black;
	  visibility: hidden;
	  -webkit-transform: scaleX(0);
	  transform: scaleX(0);
	  -webkit-transition: all 0.5s ease-in-out 0s;
	  transition: all 0.5s ease-in-out 0s;
	}
	
	.contact_info a:hover:before, .site-info a:hover:before {
	  visibility: visible;
	  -webkit-transform: scaleX(1);
	  transform: scaleX(1);
	}
	
	.custom_logo {
		margin-bottom: 2em;
		
		@include query(desktop) {
			width: element-width($number-columns: 4, $parent-total-columns: $grid-total-columns, $parent-outer-margin: 2, $outer-margin: 0);
			order: 1;
		}
	}
	
	.social_site_info {
		letter-spacing: .025em;
		@include query(desktop) {
			order: 2;
		}
	}
	
	.social-links {
		@include query(desktop) {
			text-align: right;
		}
	}
	
	.social-links ul a:before {
		@include query(desktop) {
			background: rgba(45, 176, 198, 1);
			-webkit-transition: all 0.3s ease-in-out 0s;
			transition: all 0.3s ease-in-out 0s;
		}
	}


}