.contact-block {
	i {
		padding-right: 1em;
		color: $ocean-spray-vivid;
	}
	
	.hours, .adr {
		display: flex;
		
		p {
			margin: 0;
		}
	}
}

.contact-block > div {
	margin-bottom: .5em;
}

.phone, .external-link, .email {
	a {
		display: block;
	}
}