.content-area {
	.bx-wrapper .bx-pager.bx-default-pager a {
		background-color: $ocean-spray;
	}
	
	.bx-wrapper .bx-pager.bx-default-pager a.active, .bx-wrapper .bx-pager.bx-default-pager a:focus, .bx-wrapper .bx-pager.bx-default-pager a:hover {
		background: $ocean-spray-vivid;
	}
}

#hero-slider {
	margin-top: 0;
	position: relative;
	
	ul {
		margin: 0;
	}
	
	.bx-wrapper {
		border: none;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
	}
	
	.bx-controls, .bx-controls-auto {
		margin: 0 $columns-margin;
		
		@include query(desktop) {
			margin: 0 element-width($number-columns: 1, $parent-total-columns: $grid-total-columns, $parent-outer-margin: 0, $outer-margin: 2);
		}
	}
	
	.slide {
		height: 350px;
		
		@include query(desktop) {
			height: 450px;
		}
		
		p {
			font-weight: 500;
		}
		
	}
	
	.slide_content {
		margin: $columns-margin;
	}
	
	.slide-bkgd {
		background-color: rgba(255,255,255,.75);
		padding: 2em;
		border-radius: 6px;
	}
	
	// Individual Slides

	#slide1 {
		background-image: url('../images/shutterstock_1087057631_optimized.jpg');
		background-size: cover;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		
		@include query(desktop) {
			background-position: center -100px;
		}
		
		.slide_content {
			text-align: center;
			display: none;
			
			@include query(desktop) {
				display: block;
				width: element-width($number-columns: 6, $parent-total-columns: $grid-total-columns, $parent-outer-margin: 2, $outer-margin: 0);
				height: auto;
				text-align: left;
			}
			
			h2 {
				margin-top: .25em;
				margin-bottom: .25em;
				color: $coral;
				font-family: $font__serif;
				letter-spacing: 0;
			}
		}
	}
	
	#slide2 {
		background-image: url('../images/shutterstock_551971837_optimized.jpg');
		background-size: cover;
	}
}


// Static Hero

#lodging, #dining, #attractions {
	.hero {
		margin-bottom: 2em;
	}
}