button,
input[type="button"],
input[type="reset"],
input[type="submit"],
a.button__link {
	background: $color__background-button;
	border-style: none;
	color: white;
	cursor: pointer;
	display: inline-block;
	@include font-size(0.75);
	font-weight: bold;
	line-height: 2;
	padding: 6px 15px;
	text-align: center;
	vertical-align: middle;
	text-transform: uppercase;
	-webkit-border-radius: 3px;
	   -moz-border-radius: 3px;
			border-radius: 3px;
	-webkit-box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.1);
	   -moz-box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.1);
			box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.1);
	-webkit-appearance: none;

	&:hover {
		opacity: 0.85;
	}

	&:active,
	&:focus {
		background-color: #e6e6e6;
		color: #666;
	}
}

a.button__link:visited {
	color: white;
}

.activities, .recent-posts {
	button {
		display: block;
	}
}

.activities, .recent-posts {
	
	a.button__link {	    
	    text-decoration: none;
	    color: white;
	    width: auto;
	    text-align: center;
	}
}

button.secondary {
	background-color: $ocean-spray;
}
