/*--------------------------------------------
	Fields
--------------------------------------------*/

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
	color: $color__text-input;
	border: 1px solid $color__border-input;
	border-radius: 3px;
	padding: 10px;
	font-size: .85em;
	letter-spacing: .02em;
	@include font-size(.9);
	padding: 0.6em;
	-webkit-border-radius: 2px;
	   -moz-border-radius: 2px;
			border-radius: 2px;
	-webkit-transition: 0.2s ease-in;
			transition: 0.2s ease-in;

	&:focus {
		color: $color__text-input-focus;
		outline: none;
		-webkit-transition: 0.2s ease-in;
				transition: 0.2s ease-in;
	}
}

select {
	border: 1px solid $color__border-input;
}

textarea {
	width: 100%;
	resize: vertical;
    vertical-align: top;
}

fieldset {
	border: 0;
	margin: 0;
	padding: 0;
	margin-bottom: $columns_margin;
}

input[type="search"] {
	width: 100%;
}
