
.main-navigation {
	
	@include query(desktop) {
		width: element-width($number-columns: 9, $parent-total-columns: $grid-total-columns, $parent-outer-margin: 0, $outer-margin: 0);
		margin-left: $columns-margin;
	}
	
	#top-menu {
		position: absolute;
		left: 0;
		width: 100%;
		z-index: 1000;
		background-color: $ocean-spray-vivid;
		margin-top: 1.25em;
		
		@include query(tablet) {
			margin-top: 1.5em;
			width: 50%;
			left: auto;
			right: 0;
		}
				
		@include query(desktop) {
			position: relative;
			width: auto;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;
			background-color: transparent;
		}
	}
	
	ul {
		margin: 0;
	}
	
	li {
		list-style: none;
		text-align: center;
		position: relative;
		font-size: 1em;
		letter-spacing: .1em;
		
		@include query(desktop) {
			text-align: left;
		}
	}
	
	.home {
		display: none;
	}
	
	.top-level {
		padding: 0;
		text-transform: uppercase;
		font-weight: bold;
		color: white;
		
		li {
			text-transform: capitalize;
			font-weight: 300;
		}
		
		&:hover {
			background-color: $coral;
			
			@include query(desktop) {
				background-color: transparent;
			}
		}

	}
	
	.top-level > a, .top-level > span {
		color: white;
		border-bottom: 1px solid white;
		display: block;
		font-weight: bold;
		padding: .5em;
		
		@include query(desktop) {
			color: $charcoal;
			border: 0;
		}
		
		&:hover {
			
			@include query(desktop) {
				color: $coral;
			}
		}
	}
		
	a {
		display: block;
	}
	
	.drop-down {
		background-color: white;
		display: none;
		min-width: 100%;
		padding: 1em;
		border: 1px solid $ocean-spray-vivid;
		border-top: none;
		
		@include query(desktop) {
			margin-top: 0;
			display: hidden;
			position: absolute;
			background-color: $ocean-spray-vivid;
			border: none;
			border-top: 1px solid $sandy;
		}
		
		li {
			&:not(:last-child) {
				padding-bottom: .5em;
			}
		}
		
		a {
			@include query(desktop) {
				color: white;
			}
			
			&:hover:after {
				content: '»';
				position: relative;
				left: 6px;
			}
		}
	}
	
	.hidden {
		display: none;
	}
}

.drop-down {
	position: relative;
	top: 100%;
	left: 0;
	width: 100%;
	z-index: 3;
	
	@include query(desktop) {
		width: auto;
	}
}

.menu-toggle {
	display: none;
	border: none;
	background-color: transparent;
	padding: 0;
	color: $coral;
	text-transform: uppercase;
	font-weight: 700;
	margin: 0 auto;
	box-shadow: none;
	@include font-size(1.5);
	line-height: 1em;
	border-radius: none;
	
	@include query(tablet) {
		@include font-size(1.1);
	}
	
	&:focus, &:active {
		box-shadow: none;
		transform: none;
		background-color: transparent;
		color: $coral;
	}
}

/* Hide the menu on small screens when JavaScript is available.
 * It only works with JavaScript.
 */

.js .main-navigation ul,
.main-navigation .menu-item-has-children > a > .icon,
.main-navigation .page_item_has_children > a > .icon,
.main-navigation ul a > .icon {
	display: none;
}

.js .main-navigation.toggled-on > ul {
	display: block;
}

/* Display the menu toggle when JavaScript is available. */

.js .menu-toggle {
	display: block;
	
	@include query(desktop){
		display: none;
	}
	
	span {
		display: none;
		
		@include query(tablet) {
			display: inline-block;
		}
	}
	
}

.main-navigation.toggled-on ul.nav-menu {
	display: block;
}

.toggled-on .menu-toggle .fa-bars,
.menu-toggle .fa-times {
	display: none;
	transition: all 1s;
}

.toggled-on .menu-toggle .fa-times {
	display: inline-block;
}

/*--------------------------------------------
	Breadcrumbs
--------------------------------------------*/

.breadcrumbs {
	margin: 2em element-width($number-columns: 1, $parent-total-columns: $grid-total-columns, $parent-outer-margin: 2, $outer-margin: 2);
	width: 100%;
	
	.current {
		font-weight: bold;
	}
	
	.folder {
		font-weight: 500;
	}
}

#lodging, #dining, #attractions, #single-news, #single-lodging, #single-attraction, #single-dining {
	.breadcrumbs {
		width: element-width($number-columns: 7, $parent-total-columns: 9, $parent-outer-margin: 0, $outer-margin: 0);
		margin: 0 0 1em;
	}
}

#faq {
	.breadcrumbs {
		margin: 2em element-width($number-columns: 2, $parent-total-columns: $grid-total-columns, $parent-outer-margin: 2, $outer-margin: 2);
	}
}

/*--------------------------------------------
	Site Map
--------------------------------------------*/

.site-map.mobile {
	@include query(desktop) {
		display: none;
	}
	
	.top-level > a, .top-level > span {
		
		&:hover {
			color: $coral;
		}
	}
}

.site-map.desktop {
	display: none;
	
	@include query(desktop) {
		display: block;
	}
}

.site-map {
	text-align: center;
	margin-bottom: 2em;
	
	@include query(desktop) {
		text-align: left;
		width: element-width($number-columns: 6, $parent-total-columns: 10, $parent-outer-margin: 0, $outer-margin: 0);
	}
	
	a {
		display: block;
		color: $charcoal;
		
		&:hover:after {
			content: '»';
			position: relative;
			left: 6px;
		}
	}
	
	ul {
		margin: 0;
	}
	
	ul:first-child {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		width: 100%;
		margin: 0;
		
		@include query(desktop) {
			height: 17em;
		}
	}
	
	.top-level {
		margin-bottom: 1em;
		padding-bottom: .1em;
		text-transform: uppercase;
		font-weight: bold;
		color: $charcoal;
		border-bottom: 1px solid $ash;
		
		@include query(desktop) {
			margin-bottom: .25em;
			border: 0;
		}
		
		li {
			text-transform: capitalize;
			font-weight: 300;
		}
	}
	
	.top-level > a, .top-level > span {
		font-weight: bold;
		color: $charcoal;
	}
	
	li {
		list-style: none;
		padding: .25em 0;
		position: relative;
		
		@include query(desktop) {
			width: element-width($number-columns: 2, $parent-total-columns: 6, $parent-outer-margin: 0, $outer-margin: 0);
			margin-right: $columns-margin;
		}
		
		li {
			width: 100%;
		}
	}
	
	.drop-down {
		display: none;
		
		@include query(desktop) {
			display: block;
			top: 0;
		}
	}
	
	.fa-chevron-down, .fa-chevron-up {
		position: absolute;
		right: 0;
		top: 8px;
		
		@include query(desktop) {
			display: none;
		}
	}
	
	.hidden {
		display: none;
	}
}

/*--------------------------------------------
	Social
--------------------------------------------*/

.social {
	margin-bottom: 2em;
	
	ul {
		margin: 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		
		@include query(desktop) {
			justify-content: flex-end;
		}
	}
	
	li {
		list-style: none;
		
		&:not(:first-child) {
			margin-left: .5em;
		}
	}
}

/*--------------------------------------------
	Jump Nav
--------------------------------------------*/

.jump-nav {
	margin-top: 0em;
	padding: 1.75em 0em 2em;
	border-top: 2px solid $palm-leaf;
	border-bottom: 2px solid $palm-leaf;
	
	h6 {
		margin-top: 0;
		@include font-size(1);
		text-transform: uppercase;
	}
	
	ul {
		margin: 0;
		display: flex;
		flex-wrap: wrap;
	}
	
	li {
		display: inline;
		list-style: none;
		
		&:not(:last-child) {
			margin-right: $columns-margin;
		}
	}
	
	a {
		display: block;
		font-weight: bold;
	}
}