// Brand colors
$charcoal: rgb(88,89,91);
$gray: #aaa;
$ash: rgb(220,220,220);
$light_gray: #f7f7f7;

$ocean-spray-vivid: #27c3d0;
$ocean-spray: #81e1eb;
$blue-sea: #0182bc;

$coral: #e85229;
$sunrise: #fab83e;

$seafoam-vivid: #16d2bb;
$seafoam: #ccf0dd;
$palm-leaf: #bed981;
$pale-foam: #f2fae7;

$chocolate: #4e3f34;
$sandy: #f1efc3;
$coconut: #fbfeed;


$color__background-body: $coconut;
$color__background-screen: #f1f1f1;
$color__background-hr: #ccc;
$color__background-button: $ocean-spray-vivid;
$color__background-pre: #eee;
$color__background-ins: #fff9c0;
$color__background-page: white;

$color__text-screen: #21759b;
$color__text-input: #666;
$color__text-input-focus: #111;
$color__link: $ocean-spray-vivid;
$color__link-visited: $ocean-spray-vivid;
$color__link-hover: $blue-sea;
$color__text-main: #404040;

$color__border-button: $light_gray $light_gray #bbb;
$color__border-button-hover: #ccc #bbb #aaa;
$color__border-button-focus: #aaa #bbb #bbb;
$color__border-input: $ash;
$color__border-abbr: #666;

$grid_color: hsla(204, 80%, 72%, 0.25);


