#index .intro {
	display: block;
	
	@include query(desktop) {
		display: none;
	}
}

.meta {
	margin-top: 1em;
	margin-bottom: 2em;
	
	p {
		margin: .2em 0;
	}
	
	span:not(.divider) {
		text-transform: uppercase;
		font-weight: 500;
		color: $ocean-spray-vivid;
	}
}

.news, #single-lodging, #single-attractions, #single-dining {
	
	h2 {
		margin-bottom: .25em;
		margin-top: .5em;
	}
	
	article {
		margin-top: 2em;
	}
}

.news {	
	article {
		padding-bottom: 2em;
		margin-bottom: 0em;
		
		&:not(:first-of-type) {
			padding-top: 2em;
		}
		
		&:not(:last-of-type) {
			border-bottom: 1px solid $ash;
		}
		
	}
}

#single-news {
	h1 {
		margin-bottom: .25em;
	}
}

.spa-img {
	background: url('../images/spa-3184610_1920_optimized.jpg');
	background-size: cover;
// 	background-position: center center;
}

.medium {
	width: element-width($number-columns: 4, $parent-total-columns: 8, $parent-outer-margin: 0, $outer-margin: 0);
}

.gallery .medium {
	width: 100%;
}

.medium + .content {
	width: element-width($number-columns: 4, $parent-total-columns: 8, $parent-outer-margin: 0, $outer-margin: 0);
}

.medium.alignleft + .content {
	margin-left: element-width($number-columns: 4, $parent-total-columns: 8, $parent-outer-margin: 0, $outer-margin: 1);
}

.medium.alignright + .content {
	margin-right: element-width($number-columns: 4, $parent-total-columns: 8, $parent-outer-margin: 0, $outer-margin: 1);
}

.news {
	.medium {
		width: element-width($number-columns: 4, $parent-total-columns: 7, $parent-outer-margin: 0, $outer-margin: 0);
	}
	
	.medium + .content {
		width: element-width($number-columns: 3, $parent-total-columns: 7, $parent-outer-margin: 0, $outer-margin: 0);
	}
	
	.medium.alignleft + .content {
		margin-left: element-width($number-columns: 4, $parent-total-columns: 7, $parent-outer-margin: 0, $outer-margin: 1);
	}
	
	.medium.alignright + .content {
		margin-right: element-width($number-columns: 4, $parent-total-columns: 7, $parent-outer-margin: 0, $outer-margin: 1);
	}
}

.share {
	margin-top: 0;
	
	h6 {
		text-transform: uppercase;
		border-bottom: 1px solid $ash;
		display: inline-block;
		padding-right: 1em;
		padding-bottom: .25em;
	}
	
	ul {
		display: flex;
		margin: 0;
	}
	
	li {
		list-style: none;
		margin-right: .5em;
	}
}