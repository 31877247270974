.about {
	
	margin-bottom: 5em;
	
	h2 {
		width: 100%;
		margin-top: .5em;
	}
}

#taniti-feature-img {
	background: url('../images/maori-2151594_1920_optimized.jpg');
	background-size: cover;
	background-position: center center;
}

.taniti-boat {
	background: url('../images/thailand-4798252_1920_optimized.jpg');
	background-size: cover;
	background-position: center center;
}

.taniti-plane {
	background: url('../images/plane-841441_1920_optimized.jpg');
	background-size: cover;
	background-position: center center;
}

.about-1, .about_content {
	
	@include query(tablet) {
		width: element-width($number-columns: 5, $parent-total-columns: 10, $parent-outer-margin: 0, $outer-margin: 0);
	}
}

#taniti {
	.contact-block > div {
		margin-bottom: 0;
	}
	
	.contact-block {
		margin-bottom: 1em;
		
		h6 {
			font-weight: 500;
			margin-bottom: .5em;
		}
	}
	
	.widget {
		padding-bottom: 2em;
	}
	
	.widget ul {
		display: flex;
		flex-wrap: wrap;
	}
	
	.widget ul > li {
		width: 100%;
		
		.adr-container, .hours-container {
			margin: 0 auto;
			
			@include query(desktop) {
				margin: 0;
			}
		}
		
		@include query(tablet) {
			width: element-width($number-columns: 6, $parent-total-columns: $grid-total-columns, $parent-outer-margin: 0, $outer-margin: 0);
		}
		
		@include query(desktop) {
			width: 100%;
		}
	}
}