// Rem output with px fallback
$desiredpx: 18;
@mixin font-size($sizeValue: 1) {
	font-size: ($sizeValue * $desiredpx) * 1px;
	font-size: $sizeValue * ($desiredpx/16) + rem;
}

// Center block
@mixin center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

// Clearfix
@mixin clearfix() {
	content: "";
	display: table;
	table-layout: fixed;
}

// Clear after (not all clearfix need this also)
@mixin clearfix-after() {
	clear: both;
}

// Media Queries

@mixin query($media) {
	
	
	@if $media == tablet {
		@media #{$breakpoint_tablet} {
			@content;
		}
	}
	
	@else if $media == desktop {
		@media #{$breakpoint_desktop} {
			@content;
		}
	}
	
	@else if $media == widescreen {
		@media #{$breakpoint_widescreen} {
			@content;
		}
	}
	
}

// Calculates the width, margin, or padding of an element

@function element-width($number-columns, $parent-total-columns: $grid-total-columns, $parent-outer-margin: 0, $outer-margin: 1) {
	
	// Argument Errors
	
	@if $number-columns > $parent-total-columns {
		@error "$number-columns must not exceed $parent-total-columns. You entered #{$number-columns}. The maximum number is #{$parent-total-columns}.";
	}
	
	@if $parent-outer-margin != 0 and $parent-outer-margin != 1 and $parent-outer-margin != 2 {
		@error "Parent outer margin must be 0, 1, or 2. You entered #{$parent-outer-margin}.";
	}
	
	@if $outer-margin != 0 and $outer-margin != 1 and $outer-margin != 2 {
		@error "Outer margin must be 0, 1, or 2. You entered #{$outer-margin}.";
	}
	
	// Default Width when $number-columns == $parent-total-columns
	$element-width: 100%;
	
	@if $number-columns < $parent-total-columns {
		
		/*--------------------------------------------
			Determine width of parent element
		--------------------------------------------*/
		
		// Width calculated using no outer column margins (default)
		$column-width: calc((100% / #{$parent-total-columns}) - ((#{$columns_margin} * (#{$parent-total-columns} + 1)) / #{$parent-total-columns});
		
		@if $parent-outer-margin == 1 {
			// Width calculated using one outer column margins
			$column-width: calc((100% / #{$parent-total-columns}) - #{$columns_margin});
		} @else if $parent-outer-margin == 2 {
			// Width calculated using both outer column margins
			$column-width: calc((100% / #{$parent-total-columns}) - ((#{$columns_margin} * (#{$parent-total-columns} + 1)) / #{$parent-total-columns});
		} @else {
			// Width calculated using no outer column margins (default)
			$column-width: calc((100% / #{$parent-total-columns}) - ((#{$columns_margin} * (#{$parent-total-columns} - 1)) / #{$parent-total-columns});
		}
		
		/*--------------------------------------------
			Determine width of specified element
		--------------------------------------------*/
		
		// Width calculated to include one outer margin (default)
		$element-width: calc((#{$column-width} * #{$number-columns}) + (#{$columns-margin} * (#{$number-columns})));
		
		@if $outer-margin == 0 {
			// Width calculated to include no outer margin
			$element-width: calc((#{$column-width} * #{$number-columns}) + (#{$columns-margin} * (#{$number-columns} - 1 )));
		} @else if $outer-margin == 2 {
			// Width calculated using both outer column margins
			$element-width: calc((#{$column-width} * #{$number-columns}) + (#{$columns-margin} * (#{$number-columns} + 1));
		} @else {
			// Width calculated to include one outer margin (default)
			$element-width: calc((#{$column-width} * #{$number-columns}) + (#{$columns-margin} * (#{$number-columns})));
		}
	}
	@return $element-width;
}

@function decimal($v) {
    @if (type_of($v) != number OR unit($v) != "%") {
        @error "decimal: `#{$v}` is not a percent";
    }
    @return $v / 100%;
}

// Create Grid for Development
@mixin grid() {
	//  Width calculated using both outer column margins
	$column-width: calc((100% / #{$grid-total-columns}) - ((#{$columns_margin} * (#{$grid-total-columns} + 1)) / #{$grid-total-columns});
	$grid-line: 1px;
	
	background-image: repeating-linear-gradient(
	    to right,
	    $grid_color, // Starts
	    $grid_color $grid-line,
	    transparent $grid-line,
	    transparent calc(#{$columns-margin} - #{$grid-line}), 
	    $grid_color calc(#{$columns-margin} - #{$grid-line}),
	    $grid_color $columns-margin,
	    transparent $columns-margin,
	    transparent calc(#{$column-width} + #{$columns-margin}),
	    $grid_color calc(#{$column-width} + #{$columns-margin}),
	    $grid_color calc(#{$column-width} + #{$columns-margin} - #{$grid-line})
    );
    background-size: 100% 100%;
}

// Adding Transparency to Elements

/*
@mixin transparency($attr, $color, $transparency: 1) {
	$attr: rgba(map-get( $color, 'red' ), map-get( $color, 'green' ), map-get( $color, 'blue' ), $transparency);
}
*/

@mixin transparency($attr, $color, $transparency: 1) {
	#{$attr}: rgba($color, $transparency);
}

/*
@mixin transparency($color, $transparency: 1) {
	background-color: rgba($color, $transparency);
}
*/

@mixin alpha-attribute($attribute, $color, $background) {
  $percent: alpha($color) * 100%;
  $opaque: opacify($color, 1);
  $solid-color: mix($opaque, $background, $percent);
  #{$attribute}: $solid-color;
  #{$attribute}: $color;
}

// Transition Prefixer
@mixin transition($attr, $dur: ease, $timing: 1s, $delay: 0s) {
-webkit-transition: $attr $dur $timing $delay;
   -moz-transition: $attr $dur $timing $delay;
     -o-transition: $attr $dur $timing $delay;
	 	transition: $attr $dur $timing $delay;
}


