.international, .local {
	h2, h4 {
		text-align: center;
	}
	
	i {
		display: block;
		text-align: center;
		color: $ocean-spray-vivid;
	}
	
	.gallery-item {
		padding: 3em $columns-margin $columns-margin;
		border: 1px solid $palm-leaf;
		border-radius: 10px;
	}
	
	.travel-content {
		text-align: center;
	}
}

#travel-feature-img {
	background: url('../images/cruise-601527_1920_optimized.jpg');
	background-size: cover;
	background-position: center 75%;
}