/*--------------------------------------------------------------
## Links
--------------------------------------------------------------*/
@import "links";

/*--------------------------------------------------------------
## Menus
--------------------------------------------------------------*/
@import "menus";

/*--------------------------------------------------------------
 Content Navigation
--------------------------------------------------------------*/
.post-navigation,
.paging-navigation {
	margin: 1.5em $columns-margin 2em;
	@include query(desktop) {
		margin-right: 0;
		margin-left: element-width($number-columns: 2, $parent-total-columns: 9, $parent-outer-margin: 1, $outer-margin: 2);
	}
}
[class*="navigation"] .nav-previous {
	position: relative;
	width: element-width($number-columns: 6, $parent-total-columns: 12, $parent-outer-margin: 0, $outer-margin: 0);
	
	@include query(desktop) {
		width: element-width($number-columns: 3, $parent-total-columns: 7, $parent-outer-margin: 0, $outer-margin: 1);
	}
}
[class*="navigation"] .nav-next {
	text-align: right;
	position: relative;
	width: element-width($number-columns: 6, $parent-total-columns: 12, $parent-outer-margin: 0, $outer-margin: 0);
	
	@include query(desktop) {
		width: element-width($number-columns: 3, $parent-total-columns: 7, $parent-outer-margin: 0, $outer-margin: 1);
	}
}
[class*="navigation"] .nav-previous .meta-nav:before {
	content: "\2190";
	margin-right: 5px;
	
	@include query(desktop) {
		position: absolute;
		left: -32px;
	}
}
[class*="navigation"] .nav-next .meta-nav:before {
	content: "\2192";
	margin-left: 5px;
	
	@include query(desktop) {
		position: absolute;
		right: -32px;
	}
}

.prev-title, .next-title {
	display: block;
}

.post-navigation a,
.paging-navigation a,
.comment-navigation a {
	color: #aaa;
	display: inline-block;
	font-family: $font__serif;
	@include font-size(1);
	font-style: italic;
	line-height: 1.4;
	max-width: 100%;
	word-wrap: break-word;
}
.post-navigation a:hover,
.paging-navigation a:hover,
.comment-navigation a:hover {
	color: #666;
}

.nav-links {
	display: flex;
	justify-content: space-between;
}

