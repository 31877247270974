/*--------------------------------------------
	Sidebars & Widgets
--------------------------------------------*/

.widget-area {
	overflow: hidden;
}

.widget {
	font-size: 1em;
	line-height: 1.4;
	margin-bottom: 2em;
}

.widget-title {
	font-family: "Source Sans Pro", sans-serif;
	font-size: 1.2em;
	font-weight: 600;
	line-height: 1.7143;
	margin-bottom: 0.75em;
	text-align: center;
	text-transform: uppercase;
	
	@include query(desktop) {
		text-align: left;
	}
}

#lodging, #dining, #attractions {
	.widget-title {
		margin-top: 0;
		margin-bottom: .25em;
	}
	
	.ui-group {
		margin-bottom: 1em;
		
		h6 {
			@include font-size(1.125);
			font-weight: 300;
			text-transform: uppercase;
			margin-top: .5em;
		}
	}
}

.widget ul,
.widget ol {
	list-style: none;
	margin: 0;
}

.widget ul > li,
.widget ol > li {
	padding: 0.5em 0;
	text-align: center;
	
	@include query(desktop) {
		text-align: left;
	}
}

.widget li > ul,
.widget li > ol {
// 	margin-left: 24px;
}

.widget ul ul {
	margin-top: 0.785em;
}

.widget ul ul > li:last-child {
	padding-bottom: 0;
}

.widget select {
	max-width: 100%;
}

.widget_search .search-submit {
	display: none;
}

.widget_search .search-field {
	width: 100%;
	-moz-box-sizing: border-box;
	 -ms-box-sizing: border-box;
		 box-sizing: border-box;
}

/* Sidebar Widgets */

.sidebar-widget-area .widget-title {
	color: $charcoal;
}

.sidebar-widget-area .widget ul > li,
.sidebar-widget-area .widget ol > li {
	border-top: 1px solid $light_gray;
}

.sidebar-widget-area .jetpack_widget_social_icons ul > li {
	border-top: 0;
}
.widget_calendar caption {
	text-align: center;
}
.widget_calendar td a {
	font-weight: bold;
}

