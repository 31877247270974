/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
	max-width: 100%;
}

.featured-hero {
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	height: 312px;
}

.experience-thumb {
	height: 250px;
}

.explore-thumb {
	height: 200px;
}

.medium {
	height: 250px;
}

.hero {
	width: 100%;
}


/*--------------------------------------------------------------
## Captions
--------------------------------------------------------------*/
@import "captions";

/*--------------------------------------------------------------
## Galleries
--------------------------------------------------------------*/
@import "galleries";
