html, body {
	height: 100%;
	width: 100%;
}

#page {
	width: 100%;
	background-color: white;
	max-width: $max-width;
	margin: 0 auto;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	min-height: 100%;
}

.site-content {
	display: flex;
	flex-wrap: wrap;
	padding-bottom: 2em;
}

.primary {
	margin: 0 $columns-margin;
	width: calc(100% - (2 * #{$columns-margin});
	
	@include query(desktop) {
		width: element-width($number-columns: 8, $parent-total-columns: $grid-total-columns, $parent-outer-margin: 2, $outer-margin: 0);
		margin: 0 auto;
	}
}

.alignwide {
	
	@include query(desktop) {
		margin-left: element-width($number-columns: -1, $parent-total-columns: $grid-total-columns, $parent-outer-margin: 0, $outer-margin: 0);
		margin-right: element-width($number-columns: -1, $parent-total-columns: $grid-total-columns, $parent-outer-margin: 0, $outer-margin: 0);
	}
}

.alignfull {
	margin-left: -( #{$columns-margin} );
	margin-right: -( #{$columns-margin} );
	
	@include query(desktop) {
		margin-left: element-width($number-columns: -2, $parent-total-columns: 8, $parent-outer-margin: 0, $outer-margin: 0);
		margin-right: element-width($number-columns: -2, $parent-total-columns: 8, $parent-outer-margin: 0, $outer-margin: 0);
	}
}

.alignfull-margin {
	
	@include query(desktop) {
		margin-left: element-width($number-columns: -2, $parent-total-columns: 8, $parent-outer-margin: 0, $outer-margin: 1);
		margin-right: element-width($number-columns: -2, $parent-total-columns: 8, $parent-outer-margin: 0, $outer-margin: 1);
	}
}

article {
	margin-bottom: 3em;
}

section {
	margin-top: 3em;
	margin-bottom: 3em;
}

// Column Widths

[class*="col-"] > * {
	width: 100%;
}

@for $i from 1 through $grid-total-columns {
		
	.col-m-#{$i} > * {
		@include query(tablet) {
			width: calc( ( 100% / #{$i} )  - ( (#{$columns-margin} * (#{$i} - 1)) / #{$i} ) );
		}
	}
	
	.col-m-#{$i} .gallery-item:nth-child(#{$i}n) {
		@include query(tablet) {
			margin-right: 0;
		}
		
		@include query(desktop) {
			margin-right: $columns-margin;
		}
	}
	
	.col-#{$i} > * {
		@include query(desktop) {
			width: calc( ( 100% / #{$i} )  - ( (#{$columns-margin} * (#{$i} - 1)) / #{$i} ) );
		}
	}
	
	.col-#{$i} .gallery-item:nth-child(#{$i}n) {
		@include query(desktop) {
			margin-right: 0;
		}
	}
}


@import "content-sidebar";

