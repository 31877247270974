.hero {
	height: 350px;
	
	@include query(desktop) {
		height: 450px;
	}
}

.intro {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 4em;
	
	.intro-img {
		width: 100%;
		height: 300px;
		
		@include query(desktop) {
			height: 400px;
			width: element-width($number-columns: 5, $parent-total-columns: 10, $parent-outer-margin: 0, $outer-margin: 0);
		}
	}
	
	.intro_content {
		width: 100%;
		
		@include query(desktop) {
			margin-left: $columns-margin;
			width: element-width($number-columns: 5, $parent-total-columns: 10, $parent-outer-margin: 0, $outer-margin: 0);
		}
	}
}

.visit-faq {
	background-color: $pale-foam;
	padding: 2em;
	border-radius: 10px;
	text-align: center;
	
	h5 {
		margin: 0;
	}
}

.explore-teaser .gallery-item {
	border-radius: 10px;
	box-shadow: 1px 1px 5px $ash;
	
	&:hover {
		box-shadow: 1px 1px 5px $gray;
	}
	
	a {
		display: block;
		padding: 1em 1em 1.5em;
	}
}

// Testimonial

.testimonials {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	
	h3 {
		width: 100%;
		text-align: center;
		margin-top: 0;
	}
}

.testimonial {
	margin: 0;
	padding: $columns-margin;
	border-top: 2px solid $palm-leaf;
	border-bottom: 2px solid $palm-leaf;
	margin-bottom: 2em;
	
	@include query(desktop) {
		width: element-width($number-columns: 5, $parent-total-columns: 10, $parent-outer-margin: 0, $outer-margin: 0);
	}
	
	p:first-of-type {
		margin-top: .25em;
	}
	
	footer {
		text-align: center;
	}
	
	.author {
		margin-top: 0;
		font-style: italic;
	}
}

.related-posts, .recent-posts {
	margin-bottom: 0em;
	
	h3 {
		text-align: center;
	}
	
	.post-date {
		color: $color__text-main;
	}
}

// Featured Images

#adventure-feature-img {
	background: url('../images/hawaii-839801_1920_optimized.jpg');
	background-size: cover;
// 	background-position: center center;
}

#adventure-feature-img.hero {
	
	@include query(tablet) {
		background-position: center 25%;
	}
}

#romance-feature-img {
	background: url('../images/shutterstock_151787042_optimized.jpg');
	background-size: cover;
	background-position: center 75%;
}


#family-feature-img {
	background: url('../images/shutterstock_136980212_optimized.jpg');
	background-size: cover;
	background-position: center center;
}


#culture-feature-img {
	background: url('../images/shutterstock_105859043_optimized.jpg');
	background-size: cover;
	background-position: center center;
}

#relaxation-feature-img {
	background: url('../images/shutterstock_1500687188_optimized.jpg');
	background-size: cover;

	@include query(tablet) {
		background-position: center center;
	}
}

#night-life-feature-img {
	background: url('../images/shutterstock_713125963_optimized.jpg');
	background-size: cover;
	
	@include query(tablet) {
		background-position: center center;
	}
}

// Intro Images

#romance-intro-img {
	background: url('../images/mexico-2673734_1920_optimized.jpg');
	background-size: cover;

	@include query(tablet) {
		background-position: center center;
	}
}

#adventure-intro-img {
	background: url('../images/adventure-2375717_1920_optimized.jpg');
	background-size: cover;

	@include query(tablet) {
		background-position: center center;
	}
}

#family-intro-img {
	background: url('../images/swimming-713534_1920_optimized.jpg');
	background-size: cover;

	@include query(tablet) {
		background-position: center center;
	}
}

#culture-intro-img {
	background: url('../images/fish-3062034_1920_optimized.jpg');
	background-size: cover;

	@include query(tablet) {
		background-position: center center;
	}
}

#relaxation-intro-img {
	background: url('../images/books-918521_1920_optimized.jpg');
	background-size: cover;

	@include query(tablet) {
		background-position: center center;
	}
}

#night-life-intro-img {
	background: url('../images/night-911995_1920_optimized.jpg');
	background-size: cover;

	@include query(tablet) {
		background-position: center center;
	}
}