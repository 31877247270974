.site-header {
	padding: .75em $columns-margin .5em;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: center;
	
	@include query(desktop) {
		justify-content: flex-start;
		padding: 2em $columns-margin;
		align-items: baseline;
	}
	
	a {
		white-space: nowrap;
	}
	
	&:before, &:after {
		content: none;
	}
}

.site-branding {
	width: element-width($number-columns: 12, $parent-total-columns: $grid-total-columns, $parent-outer-margin: 0, $outer-margin: 0);
	width: element-width($number-columns: 10, $parent-total-columns: $grid-total-columns, $parent-outer-margin: 0, $outer-margin: 0);
	font-family: $font__serif;
	@include font-size(2.1);
	
	a {
		color: $ocean-spray-vivid;
	}
	
	@include query(tablet) {
		width: element-width($number-columns: 8, $parent-total-columns: $grid-total-columns, $parent-outer-margin: 0, $outer-margin: 0);
	}
	
	@include query(desktop) {
		width: element-width($number-columns: 3, $parent-total-columns: $grid-total-columns, $parent-outer-margin: 0, $outer-margin: 0);
		margin-bottom: 0;
		text-align: center;
	}
}

// See _menus.scss for Primary Nav