#cjd-utility {
	position: fixed;
	width: 100%;
	z-index: 5000;
	background-color: black;
	top: 0;
	
	.container {
		max-width: $max-width;
		margin: 0 auto;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		align-items: center;
		padding: .75em $columns-margin;
		
		@include query(tablet) {
			justify-content: space-between;
		}
	}
	
	h6 {
		margin: 0;
		@include font-size(.75);
		font-weight: 500;
		color: white;
		text-transform: uppercase;
		letter-spacing: .075em;
	}
	
	button {
		width: 120px;
		padding: 0;
		letter-spacing: .03em;
		margin-top: 1em;
		
		@include query(tablet) {
			margin-top: 0;
		}
	}
}