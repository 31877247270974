.faq {
	margin-top: 3em;
	
	ul {
		margin: 0;
	}
	
	li {
		list-style: none;
		margin-bottom: 2em;
		position: relative;
	}
	
	.hidden {
		display: none;
	}
	
	.fa-chevron-down, .fa-chevron-up {
		position: absolute;
		top: 2em;
		right: 1.5em;
		color: $gray;
		transition: .15s ease;
	}
	
	.question {
		padding: .5em 4em .5em 2em;
		border: 1px solid $ocean-spray-vivid;
// 		background-color: $pale-foam;
		color: $ocean-spray-vivid;
		
		&:hover {
			opacity: 0.85;
			color: $blue-sea;
			
			i {
				color: $ocean-spray-vivid;
				color: $blue-sea;
			}
		}
		
		p {
			font-weight: bold;
			text-transform: uppercase;
		}
		
		i {
			color: $ocean-spray-vivid;
		}
		
		&.open {
			background-color: $ocean-spray-vivid;
			color: white;
			
			i {
				color: white;
			}
		}
	}
	
	.answer {
		padding: 1em 2em;
		border: 1px solid $ocean-spray-vivid;
		border-top: none;
		display: none;
	}
}

#faq-feature-img {
	background: url('../images/shutterstock_1090857887_optimized.jpg');
	background-size: cover;
}

#faq-feature-img.hero {
	
	@include query(tablet) {
// 		background-position: center center;
	}
}

// Contact Form

hr {
	background-color: $ash;
	margin: 4em 0;
}

 #contact_info form {
    margin-bottom: 1em;
}

input, textarea {
    padding: 10px;
    width: 100%;
    border-radius: 4px;
}

.submit {
	padding-top: 0;
	
	input {
		width: auto;
	}
}

input:focus, textarea:focus {
    border: 1px solid $ocean-spray-vivid;
}

textarea {
    resize: vertical;
    vertical-align: top;
}

fieldset {
    margin-bottom: 1.363636363636%; /* 15 / 1100 = 0.01363636363636 */
    
    ul {
	    margin: 0;
    }
}

#contact legend, #contact label {
    display: none;
}

#name ul li {
    display: inline-block;
}

#name_set {
	margin-bottom: 0;
	
	@include query(tablet) {
		margin-bottom: 1.363636363636%;
	}
	
	ul {
		display: flex;
		flex-wrap: wrap;
		
		li {
			width: 100%;
			
			@include query(tablet) {
				width: element-width($number-columns: 6, $parent-total-columns: 12, $parent-outer-margin: 0, $outer-margin: 0);
			}
			
			&:first-of-type {
				
				@include query(tablet) {
					margin-right: $columns-margin;
				}
			}
		}
	}
	
	input {
	    margin-bottom: 1.363636363636%; /* 15 / 1100 = 0.01363636363636 */
	    
	    @include query(tablet) {
		    margin-bottom: 0;
	    }
    }
}

.error {
    margin-bottom: .5em;
}

#contact .error ul {
    margin-bottom: 0;
}

#contact ul li {
    margin-bottom: 0;
    font-size: .9em;
    color: red;
    list-style: none;
}