.wire_border {
	border: 2px solid $charcoal;
}

.wire_logo {
	text-align: center;
	font-weight: 700;
	@include font-size(1.5);
	padding: 1em;
}

.wire_image {
	background-color: $light_gray;
	display: flex;
	justify-content: space-around;
	align-content: center;
	flex-wrap: wrap;
	padding: $columns-margin;
}

.fa-image {
	
}

.wire_description {
	width: 100%;
	text-align: center;
	@include font-size(.9);
	font-style: italic;
	margin-top: 1em;
	
	p {
		margin: 0;
	}
}

.wire_p {
	margin-top: 26px;
	margin-bottom: 26px;
	position: relative;
}

.wire_text-line {
	display: block;
	position: relative;
	height: 18px;
	background-color: $ash;
	margin-top: 8px;
}

.wire_text-container {
	display: block;
	position: relative;
	height: 18px;
	background-color: transparent;
	margin: 8px 0 0 0;
	display: flex;
	
	span {
		margin-top: 0;
		margin-right: 12px;
	}
}

/*
[class*="line-length-"] {
	display: inline-block;
}
*/

// Text Line Lengths

@for $i from 10 through 30 {
	.line-length-#{$i} {
		width: calc(#{$i} * 1%);
	}
}

@for $i from 40 through 60 {
	.line-length-#{$i} {
		width: calc(#{$i} * 1%);
	}
}

@for $i from 94 through 100 {
	.line-length-#{$i} {
		width: calc(#{$i} * 1%);
	}
}

.question .wire_text-line:last-of-type::after {
	content: '?';
	position: absolute;
	right: -20px;
	top: -7px;
	@include font-size(1.25);
	font-weight: 900;
	color: $gray;
	transition: .15s ease;
}

.question.wire_p {
	margin-bottom: 0;
	
	.wire_text-line {
		background-color: $gray;
		transition: .15s ease;
	}
	
	.wire_text-line:first-child {
		margin-top: 0;
	}
}

.question:hover {
	.wire_text-line {
		background-color: white;
	}
	
	.wire_text-line:last-of-type::after {
		color: white;
	}
	
	.fa-chevron-down, .fa-chevron-up {
		color: white;
	}
}

.question.open {
	background-color: $gray;
	
	.wire_text-line {
		background-color: white;
	}
	.wire_text-line:last-of-type::after {
		color: white;
	}
	
	.fa-chevron-down, .fa-chevron-up {
		color: white;
	}
}

.answer.wire_p {
	margin-top: 0;
	
	.wire_text-line:first-child {
		margin-top: 0;
	}
}

.wire_jump {
	margin-top: 3em;
	padding: 1.75em 0em 2em;
	border-top: 2px solid $ash;
	border-bottom: 2px solid $ash;
	
	h6 {
		margin-top: 0;
		@include font-size(1);
		text-transform: uppercase;
	}
	
	ul {
		margin: 0;
		display: flex;
		flex-wrap: wrap;
	}
	
	li {
		display: inline;
		list-style: none;
		
		&:not(:last-child) {
			margin-right: $columns-margin;
		}
	}
	
	a {
		display: block;
		font-weight: bold;
	}
}

.wire_contact-line {
	
	margin-bottom: 1em;
	
	i {
		float: left;
	}
	
	.wire_text-line {
		margin-left: 2em;
	}
	
	.wire_text-container {
		margin-left: 2em;
		
		.wire_text-line {
			margin-left: 0;
		}
	}
	
	.street-address {
		margin-top: 0;
		margin-bottom: 0;
	}
	
	.address-line-2 {
		padding-right: 10%;
	}
	
	.hours-line {
		padding-right: 0%;
	}
}