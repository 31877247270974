#news, #single-news, #single-lodging, #single-attraction, #single-dining {
	
	.site-content {
		border-top: 8px solid $palm-leaf;
		padding-top: 2em;
	}
}

.content-area.has-sidebar {
	
	@include query(desktop) {
		margin: 0;
		width: element-width($number-columns: 9, $parent-total-columns: 12, $parent-outer-margin: 2, $outer-margin: 0);
	}
}

.sidebar-widget-area {
	width: 100%;
	margin: 1em $columns-margin;
	
	@include query(desktop) {
		width: element-width($number-columns: 3, $parent-total-columns: 12, $parent-outer-margin: 2, $outer-margin: 1);
		padding: 0 $columns-margin;
		margin: 0;
		margin-right: $columns-margin;
		border-right: 1px solid $ash;
	}
}


#news .content-area.has-sidebar, #single-news .content-area.has-sidebar, , #taniti .content-area.has-sidebar {
	
	@include query(desktop) {
		margin: 0;
		margin-left: element-width($number-columns: 1, $parent-total-columns: $grid-total-columns, $parent-outer-margin: 2, $outer-margin: 2);
		width: element-width($number-columns: 7, $parent-total-columns: 12, $parent-outer-margin: 2, $outer-margin: 0);
	}
}

#news .sidebar-widget-area, #single-news .sidebar-widget-area, #taniti .sidebar-widget-area {
	width: 100%;
	margin: 1em $columns-margin;
	
	@include query(desktop) {
		margin-left: element-width($number-columns: 1, $parent-total-columns: $grid-total-columns, $parent-outer-margin: 2, $outer-margin: 2);
		width: element-width($number-columns: 3, $parent-total-columns: 12, $parent-outer-margin: 2, $outer-margin: 0);
		padding: 0;
		padding-left: $columns-margin;
		margin-right: 0;
		border: none;
		border-left: 1px solid $ash;
	}
}

