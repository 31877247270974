body,
button,
input,
select,
optgroup,
textarea {
	color: $color__text-main;
	font-family: $font__main;
	@include font-size(1);
	line-height: $font__line-height-body;
	font-weight: 300;

	
/*
	@media screen and (min-width: 700px) {
		@include font-size(1);
	}
*/
}


@import "headings";

@import "copy";
