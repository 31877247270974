#lodging, #dining, #attractions {
	.primary {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-content: baseline;
	}
	
	main {
		width: 100%;
	}
}

.button-group, .filter-button-group {
	
	button {
		margin-right: 10px;
		margin-bottom: 10px;
	}
	
	.is-checked {
		background-color: $blue-sea;
	}
}

.location-details {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: 5em;
	border-top: 1px solid $ash;
	padding-top: 3em;
	
	@include query(desktop) {
		padding-left: element-width($number-columns: 1, $parent-total-columns: $grid-total-columns, $parent-outer-margin: 0, $outer-margin: 2);
// 		padding-right: element-width($number-columns: 1, $parent-total-columns: $grid-total-columns, $parent-outer-margin: 0, $outer-margin: 2);
	}
	
	.contact-block {
		width: 100%;
		margin-top: 1em;
		
		@include query(desktop) {
			margin-top: 0;
			margin-left: $columns-margin;
			width: element-width($number-columns: 4, $parent-total-columns: 9, $parent-outer-margin: 0, $outer-margin: 0);
		}
	}
	
	h6 {
		width: 100%;
		text-transform: uppercase;
		
		@include query(desktop) {
			margin-left: 32px;
		}
	}
}

/*
#lodging, #dining, #attractions {
	.grid-item {
		border-radius: 10px;
		box-shadow: 1px 1px 5px $ash;
		
		&:hover {
			box-shadow: 1px 1px 5px $gray;
		}
		
		a {
			display: block;
			padding: 1em 1em 1.5em;
		}
	}
}
*/

.option-name {
	padding-bottom: .15em;
	border-bottom: 1px solid $palm-leaf;
	margin-bottom: .4em;
	margin-top: .5em;
}

.option-meta {
	
	p {
		color: $color__text-main;
		margin: 0;
	}
	
	.location {
		width: 100%;
	}

}

.divider {
	margin: 0 .5em;
}

#the-islander_explore-thumb {
	background: url('../images/shutterstock_356154083_optimized.jpg');
	background-size: cover;
// 	background-position: center center;
}

.bath-view {
	background: url('../images/shutterstock_18659605_optimized.jpg');
	background-size: cover;
// 	background-position: center center;
}

#dining-feature-img {
	background: url('../images/fish-2105233_1920_optimized.jpg');
	background-size: cover;


	@include query(tablet) {
		background-position: center center;
	}
}

#lodging-feature-img {
	background: url('../images/bora-bora-3023437_1920_optimized.jpg');
	background-size: cover;
// 	background-position: center center;

	@include query(tablet) {
		background-position: center bottom;
	}
}

#attractions-feature-img {
	background: url('../images/surfing-2212948_1920_optimized.jpg');
	background-size: cover;
// 	background-position: center center;

	@include query(tablet) {
		background-position: center center;
	}
}