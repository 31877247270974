//  Breakpoints
	$tablet_width: 33em;
	$desktop_width: 60em;
	$widescreen_width: 68.75em;

	$breakpoint_tablet: "screen and (min-width: #{$tablet_width})";
	$breakpoint_desktop: "screen and (min-width: #{$desktop_width})";
	$breakpoint_widescreen: "screen and (min-width: #{$widescreen_width})";



/*--------------------------------------------
	Grid Variables
--------------------------------------------*/

//  Max Width of total columns
	$max-width: 72rem !default;
//  Width of gutter in Grid
	$columns-margin: 32px !default;
//  Total Columns in Grid
	$grid-total-columns: 12 !default;
	$desktop-total-columns: 12 !default;

/*
	$total-columns: (
		"mobile": 2,
		"tablet": 6,
		"desktop": 12,
		"widescreen": 14
	)
*/

	$media-queries: (
		'tablet': 'm', 
		'desktop': 'l', 
		'widescreen': 'w'
	)

/*--------------------------------------------
	Content Alignment Sizes
--------------------------------------------*/

/*
$content-alignment-sizes: (
	"primary": 8,
	"alignwide": 10,
	"alignfull": 12
)
*/
