.gallery {
	display: flex;
	flex-wrap: wrap;
	margin: 0;
}

.gallery-item {
	list-style: none;
	margin-bottom: 2em;
	
	@include query(tablet) {
		margin-right: $columns-margin;
	}
	
	h5 {
		margin-bottom: .25em;
	}
	
	h5 ~ p {
		margin-top: 0;
	}
}


// Grid

.grid {
	display: flex;
	flex-wrap: wrap;
	margin: 0;
}

.grid-item, .grid-sizer {
	width: 100%;
	
	@include query(tablet) {
		width: element-width($number-columns: 6, $parent-total-columns: $grid-total-columns, $parent-outer-margin: 0, $outer-margin: 0);
	}
	
	@include query(desktop) {
		width: element-width($number-columns: 3, $parent-total-columns: 9, $parent-outer-margin: 0, $outer-margin: 0);
	}
}

.grid-item {
	list-style: none;
	margin-bottom: 3em;
	min-height: 350px;
}
