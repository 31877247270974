.alignleft {
	display: inline;
	float: left;
	margin-right: 1.5em;
}

.alignright {
	display: inline;
	float: right;
	margin-left: 1.5em;
}

.aligncenter {
	clear: both;
	@include center-block;
}


// Mobile Alignments

.align-content-left {
	text-align: left;
}

.align-content-right {
	text-align: right;
}

.align-content-center {
	text-align: center;
}

.align-content-left .wire_text-line, .button.align-content-left {
	margin-left: 0;
	margin-right: auto;
}

.align-content-center .wire_text-line, .button.align-content-center {
	margin-left: auto;
	margin-right: auto;
}

.align-content-right .wire_text-line, .button.align-content-right {
	margin-right: 0;
	margin-left: auto;
}

@each $media, $class-ext in $media-queries {
	
	@include query(#{$media}) {
		.#{$class-ext}-align-content-left {
			text-align: left;
		}
		
		.#{$class-ext}-align-content-right {
			text-align: right;
		}
		
		.#{$class-ext}-align-content-center {
			text-align: center;
		}
	
		.#{$class-ext}-align-content-left .wire_text-line {
			margin-left: 0;
			margin-right: auto;
		}
		
		.#{$class-ext}-align-content-center .wire_text-line {
			margin-left: auto;
			margin-right: auto;
		}
		
		.#{$class-ext}-align-content-right .wire_text-line {
			margin-right: 0;
			margin-left: auto;
		}
		
		.button.#{$class-ext}-align-content-left {
			margin-left: 0;
			margin-right: auto;
		}
		
		.button.#{$class-ext}-align-content-center {
			margin-left: auto;
			margin-right: auto;
		}
		
		.button.#{$class-ext}-align-content-right {
			margin-right: 0;
			margin-left: auto;
		}
		
	}
}
