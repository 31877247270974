.map-toggle {
	margin-bottom: 1em;
	color: $ocean-spray-vivid;
	background-color: transparent;
	box-shadow: none;
	padding: 0;
	@include font-size(1.125);
}

#map {
	height: 400px;
	position: relative;
	margin-top: 0;
	width: 100%;
	margin-top: 0;
	margin-bottom: 2em;
	display: none;
	
	&.toggled-on {
		display: block;
	}
}

#map.single {
	display: block;
	height: 400px;
	width: 100%;
	@include query(desktop) {
		width: element-width($number-columns: 5, $parent-total-columns: 9, $parent-outer-margin: 0, $outer-margin: 0);
	}
}