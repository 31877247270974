// Grid Layout for Development

html {
	position: relative;
}

html::before {
	display: none;
	position: fixed;
	top: 0; right: 0; bottom: 0; left: 0;
	margin-right: auto;
	margin-left: auto;
	width: 100%;
	max-width: $max-width;
	min-height: 100%;
	content: '';
	@include grid();
	z-index: 1000;
}

html.grid-visible::before {
	display: block;
}

/*--------------------------------------------------------------
# Wireframe Elements
--------------------------------------------------------------*/
@import "wireframe/wireframe";

/*--------------------------------------------------------------
# Wireframe Elements
--------------------------------------------------------------*/
@import "cjd-utility/cjd-utility";