
%heading {
	clear: both;
	font-family: $font__sans;
	letter-spacing: 1px;
}

$heading-list: h6, h5, h4, h3, h2, h1;
$heading-weight: 300, 500, 300, 300, 100, 100;
$heading-line-height: 1em, 1em, 1.125em, 1em, 1em, 1.1em;
    
@each $heading in $heading-list {
    $i: index($heading-list, $heading);
    #{$heading} {
	    @extend %heading;
	    font-size: modular-scale($i, 1em, $minor-third);
	    font-weight: nth($heading-weight, $i);
	    line-height: nth($heading-line-height, $i);
	    margin-bottom: .75em;
	    margin-top: 1em;
	    line-height: 1.1em;
	    
	    @include query(tablet) {
		    font-size: modular-scale($i, 1.125em, $minor-third);
	    }
    }
}

.explore-teaser h3, .recent-posts h3 {
	text-align: center;
}

.intro h2 {
	
	@include query(desktop) {
		margin: .5em 0;
	}
	
	.accent {
		margin-left: 1.15em;
	}
}

#index .intro h2 {
	font-family: $font__serif;
	letter-spacing: 0;
}

.accent {
	font-family: $font__serif;
	letter-spacing: 0;
	color: $ocean-spray-vivid;
	margin: 0 .2em 0 .1em;
}

h2 .accent {
	@include font-size(3.25);
}

h4 .accent {
	@include font-size(2.5);
}